import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import addToMailchimp from "gatsby-plugin-mailchimp"

import ogImage from "../images/referral.png"

const SummerOffer = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [referree, setReferree] = useState("")
  const [referreeEmail, setReferreeEmail] = useState("")
  const [org, setOrg] = useState("")
  const [reason, setReason] = useState("")

  const [isSuccess, setIsSuccess] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(
      email,
      {
        NAME: name,
        REFERREE: referree,
        REF_EMAIL: referreeEmail,
        ORG: org,
        REASON: reason,
      },
      "https://quantcopy.us17.list-manage.com/subscribe/post?u=7323b3f565a395e36c758893b&amp;id=b7aa561421"
    )
      .then(data => {
        if (data.result === "error") {
          alert(data.msg)
        }
        if (data.result === "success") {
          setIsSuccess(true)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <Layout variant="useCase">
      <SEO
        title="£50 for you this August Terms and Conditions"
        description="Spend just 30 mins with us this summer and you'll receive a £50 Amazon gift card. "
        image={ogImage}
      />

      <section className="container text-white mx-auto p-4 sm:p-6">
        <div className="max-w-3xl flex flex-col gap-4 mt-24">
          <h2 className="uppercase text-medium-grey">
            Offer Terms and Conditions
          </h2>
          <h1 className="text-6xl font-bold">
            Smash your H2 offer - Aug 2023: Terms & Conditions
          </h1>
        </div>

        <div className="flex flex-col md:flex-row gap-24 my-12">
          <div className="flex-1 prose prose-xl">
            <p>
              <strong>Terms and Conditions</strong>
            </p>
            <h3 id="-schedule-and-attend-a-demonstration-of-tactic-before-1st-september-2023-and-get-a-50-amazon-gift-card-">
              <strong>
                Schedule and attend a demonstration of Tactic before 1st
                September 2023 and get a £50 Amazon Gift Card.
              </strong>
            </h3>
            <h3 id="-about-the-promotion-">
              <strong>About the Promotion</strong>
            </h3>
            <ul>
              <li>
                This Promotion is offered by Quantcopy Growth AI Ltd Trading as
                Tactic with Company Number 11808232 (Tactic, we, us, our)
              </li>
              <li>
                Schedule and attend a demonstration of Tactic before 1st
                September 2023 and get a £50 Gift Card (
                <strong>Promotion</strong>).
              </li>
              <li>
                This promotion applies from 00:00 AM BST Monday 14th August 2023
                to 11:59 PM Friday 1st September 2023 BST (
                <strong>Promotion Period)</strong>.
              </li>
            </ul>
            <h3 id="-how-to-qualify-and-enter-for-the-promotion-eligible-participants-">
              <strong>How to qualify and enter for the Promotion</strong> (
              <strong>Eligible Participants)</strong>
            </h3>
            <ul>
              <li>
                To qualify for the promotion, you must:
                <ul>
                  <li>
                    Receive an email directly from Tactic with reference to this
                    Promotion. Any recipients of a forwarded email and/or who
                    are not featured on Tactic's distribution list will be
                    automatically disqualified;
                  </li>
                  <li>
                    Submit the online booking form link in the email or be
                    accepted into the promotion at the discretion of an Tactic
                    representative;
                  </li>
                  <li>
                    Attend in full your scheduled demonstration before 1st
                    September 2023;
                  </li>
                  <li>
                    Not have been an Tactic customer within the past 12 months
                    (for the avoidance of doubt, existing customers, and their
                    existing or new entities including related bodies corporate
                    or subsidiaries are not eligible for this Promotion);
                  </li>
                  <li>
                    Not have attended a demonstration for Tactic in the past 3
                    months
                  </li>
                </ul>
              </li>
            </ul>
            <h3 id="-reward-">
              <strong>Reward</strong>
            </h3>
            <ul>
              <li>
                You will receive an Amazon e-gift card valued at £50 GBP via
                email, no later than 14th September 2023 if demonstration is
                attended before the end of the Promotion Period.
              </li>
              <li>
                The e-gift card will be supplied by Amazon and instructions for
                use will be provided with the e-gift card
              </li>
            </ul>
            <h3 id="-other-terms-and-conditions-that-apply-">
              <strong>Other Terms and Conditions that apply</strong>
            </h3>
            <ul>
              <li>
                Standard Tactic Platform{" "}
                <a href="https://tactic.fyi/terms-and-conditions.html">
                  Terms and Conditions
                </a>{" "}
                apply.
              </li>
              <li>
                This Promotion is managed in accordance with the{" "}
                <a href="https://help.quantcopy.com/privacy-policy">
                  Tactic Privacy Policy
                </a>
                .
              </li>
            </ul>
            <h3 id="-liability-">
              <strong>Liability</strong>
            </h3>
            <ul>
              <li>
                Except for any liability that cannot be excluded by law, Tactic
                (including its officers, employees and agents) excludes all
                liability (including negligence), whether direct, indirect,
                special or consequential, for any loss or damage arising in in
                connection with this Promotion, including any:
                <ul>
                  <li>
                    technical difficulties or equipment malfunction (whether or
                    not under Tactic's control);
                  </li>
                  <li>
                    theft, unauthorised access or third party interference;
                  </li>
                  <li>
                    entries that are late, lost, altered, damaged or misdirected
                    (whether or not after Tactic receives it) due to any reason
                    beyond Tactic's reasonable control;
                  </li>
                  <li>
                    variation in Reward value to that stated in these terms; or
                  </li>
                  <li>tax liability incurred by an Eligible Participant.</li>
                </ul>
              </li>
            </ul>
            <h3 id="-general-terms-">
              <strong>General Terms</strong>
            </h3>
            <ul>
              <li>
                The Reward is limited to 1 gift card per business (including
                related bodies corporate or subsidiaries).
              </li>
              <li>
                The Reward is not transferable to any other products or cash.
              </li>
              <li>
                Separate Terms and Conditions governing the use of the Reward
                and provided on the Amazon website will apply to the Eligible
                Participant's use of the Reward.
              </li>
              <li>
                The Reward is granted 'as is' and Tactic does not provide a
                warranty of the Reward's merchantability, or fitness for a
                particular purpose.
              </li>
              <li>
                Tactic reserves the right to terminate the Promotion early in
                full or in part at its absolute discretion.
              </li>
              <li>
                Laws may apply to you or to Tactic as a result of your specific
                location. If this is the case, and if those laws are
                inconsistent with any part of these terms, those laws will
                prevail to the extent of the inconsistency.
              </li>
              <li>
                Severability: If any part or provision of these terms are
                invalid, unenforceable or in conflict with the law, that part or
                provision is replaced with a provision which, as far as
                possible, accomplishes the original purpose of that part or
                provision. The remainder of these terms will be binding on the
                parties.
              </li>
              <li>
                Governing Law: These terms are governed by the laws of England
                and Wales.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SummerOffer
